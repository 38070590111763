import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { resolveBlogCategorySlug } from '../../utils';

function BlogCategories() {
  const { categories } = useStaticQuery(graphql`
    query AllBlogCategories {
      categories: allWpCategory(
        sort: { databaseId: ASC }
        filter: { databaseId: { ne: 4 } }
      ) {
        edges {
          node {
            id
            name
            uri
            slug
            databaseId
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="row">
        {categories.edges.map(({ node }) => (
          <Link
            to={resolveBlogCategorySlug(node.slug)}
            className="col-md-4 card-service"
            key={node.id}>
            <span
              className="text-headline"
              dangerouslySetInnerHTML={{
                __html: node.name.replaceAll(' ', '<br/>'),
              }}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}

BlogCategories.propTypes = {};

export default BlogCategories;
