import React from 'react';
import { graphql } from 'gatsby';
import CtaDeck from '../components/common/cta-deck';
import Layout from '../components/layout';
import CommonCard from '../components/common/common-card';
import { createBlogLink } from '../utils';
import PaginationLinks from '../components/success-stories/pagination-links';
import BlogCategories from '../components/blog/Categories';
import Breadcrumbs from '../components/blog/breadcrumbs';

function SuccessStories({ location, data, pageContext }) {
  const { categoryList } = data;

  const { name, slug, seo } = categoryList.edges[0].node;

  const headMeta = {
    title: seo.title,
    description: seo.metaDesc || null,
    keywords: seo.metaKeywords,
  };

  return (
    <Layout headMeta={headMeta} location={location}>
      <section id="posts" className="blog">
        <div className="container blog-post">
          <div className="posts-wrapper">
            <Breadcrumbs categoryName={name} />
            <h1 className="text-hero text-grey mb-10" id="title-success-stories">
              Category: {name}
            </h1>
            <div className="row" id="loaded-posts">
              {data.allWpPost.edges.map((edge) => (
                <CommonCard
                  className="col-lg-6 mb-6 mb-lg-8"
                  title={edge.node.title}
                  description={edge.node.excerpt}
                  link={createBlogLink(edge.node.slug)}
                  imgSrc={edge.node?.featuredImage?.node?.localFile || data.fallbackImage}
                  date={edge.node.date}
                  key={edge.node.id}
                />
              ))}
            </div>
          </div>
          <PaginationLinks
            activePageIndex={pageContext.currentPage - 1}
            pageCount={pageContext.numPages}
            pageSubDir="/page/"
            baseLink={`/blog/category/${slug}`}
          />
        </div>

        <CtaDeck />

        <div className="container pt-20">
          <BlogCategories />
        </div>
      </section>
    </Layout>
  );
}

SuccessStories.propTypes = {};

export const query = graphql`
  query AllBlogCategoriesPage(
    $skip: Int!
    $limit: Int!
    $filter: WpPostFilterInput
    $categoryId: Int!
  ) {
    allWpPost(limit: $limit, sort: { date: DESC }, filter: $filter, skip: $skip) {
      edges {
        node {
          id: databaseId
          title
          date
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    breakpoints: [750, 1080, 1366, 1920]
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              altText
            }
          }
          slug
          excerpt
        }
      }
    }
    fallbackImage: file(name: { regex: "/statue-liberty/" }) {
      id
      name
      childImageSharp {
        gatsbyImageData(
          breakpoints: [750, 1080, 1366, 1920]
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    categoryList: allWpCategory(filter: { databaseId: { eq: $categoryId } }) {
      edges {
        node {
          id
          name
          databaseId
          count
          slug
          seo {
            title
            metaDesc
            metaKeywords
          }
        }
      }
    }
  }
`;

export default SuccessStories;
